.main {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 40px;
}

.main__working {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Lanes */
.lanes__wrapper {
  display: flex;
  flex-direction: column;
}

/* Modal */
.mood-modal__body {
  display: flex;
  position: absolute;
  inset: 0;
  background: #5ac8facf;
  overflow: auto;
  padding: 20px;
  justify-content: center;
}

.mood-modal__close-button {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  cursor: pointer;
}
.mood-modal__close-button:hover {
  opacity: 1;
}
.mood-modal__close-button:before,
.mood-modal__close-button:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white;
}
.mood-modal__close-button:before {
  transform: rotate(45deg);
}
.mood-modal__close-button:after {
  transform: rotate(-45deg);
}

/* Modal Mood Buttons */
.mood-button__wrapper {
  padding: 0.5rem;
  margin: 0 0.25rem;
}

.mood-button__wrapper:hover {
  background-color: white;
  border-radius: 50%;
}

.mood-button__wrapper:hover > .mood-button__lines {
  display: none;
}

.mood-button__wrapper:hover > .mood-button__flat {
  display: inline-block;
}

.mood-button__wrapper.mood-button__selected {
  background-color: white;
  border-radius: 50%;
  cursor: default;
}

.mood-button__wrapper.mood-button__selected > .mood-button__flat {
  display: inline-block;
}
.mood-button__wrapper.mood-button__selected > .mood-button__lines {
  display: none;
}

.mood-button__flat {
  display: none;
}

.mood-button__lines {
  display: inline-block;
}
